@use '@angular/material' as mat;
@import "@angular/material/prebuilt-themes/indigo-pink.css";
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$yamazumi-app-primary: mat.define-palette(mat.$indigo-palette);
$yamazumi-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$yamazumi-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$yamazumi-app-theme: mat.define-light-theme((
  color: (
    primary: $yamazumi-app-primary,
    accent: $yamazumi-app-accent,
    warn: $yamazumi-app-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($yamazumi-app-theme);

@import '@angular/material/theming';
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

$MES-theme-primary: mat.define-palette(mat.$light-blue-palette, 800, 600, 900);
$MES-theme-accent: mat.define-palette(mat.$orange-palette, A200, A100, A400);
$MES-theme-warn: mat.define-palette(mat.$red-palette);

$MES-theme: mat.define-light-theme($MES-theme-primary, $MES-theme-accent, $MES-theme-warn);

@include mat.all-legacy-component-themes($MES-theme);

@import "component-styles";
@import "src/styles_flex.scss";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@mixin md-icon-size($size: 36px) {
  font-size: $size;
  height: $size;
  width: $size;
  line-height: $size;
}

.md-drppicker .btn {
  background-color: #0277BD !important;
}

.md-drppicker td.active{
  background-color: #0277BD !important;
}

.dateRangeBut{
  max-width: 300px;
  margin: 5px;
}

.banner{
  background: #0277BD;
  color: white;
}

table {
  @include gea-table-theme;
}

.sidenav-container {
  height: 100%;
}

.sidenav {
  width: 200px;
}

.sidenav .mat-toolbar {
  background: inherit;
}

.mat-toolbar.mat-primary {
  position: sticky;
  top: 0;
  z-index: 1;
}

.flex {
  flex: 1 1 auto;
}

.mat-dialog-actions {
  // ...
  &[align='end'] {
    justify-content: flex-end;
  }

  &[align='center'] {
    justify-content: center;
  }
}

.mat-progress-bar-fill::after {
  background-color: #124b9b;
}

.mat-progress-bar-buffer {
  background: #E4E8EB;
}

.mat-progress-bar {
  border-radius: 2px;
}

.gea-button-primary {
  width: 100%;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: large;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.card-dialog {
  min-width: 225px;
  max-width: 500px;
  width: 100%;
}

.spacer {flex: 1 1 auto;}

.error {
  font-size: 14px;
  color: red;
}

.navlink {
  font-weight: bold;
  font-size: large;
  color: darkblue;
}
